import { autoBrightness } from '@cloudinary/url-gen/actions/adjust';
import { format, quality } from '@cloudinary/url-gen/actions/delivery';
import { thumbnail } from '@cloudinary/url-gen/actions/resize';
import { byRadius } from '@cloudinary/url-gen/actions/roundCorners';
import { FocusOn } from '@cloudinary/url-gen/qualifiers/focusOn';
import { focusOn } from '@cloudinary/url-gen/qualifiers/gravity';
import { api } from '../api';
import WefundLogoGif from '../assets/wefund-logo.gif';
import '../styles/components/Generated.scss';

type GeneratedProps = {
	name: string;
	occupation: string;
	phone: string;
	email: string;
	publicId: string;
};

const Generated = ({ name, occupation, phone, email, publicId }: GeneratedProps) => {
	const avatar = api?.image(publicId);
	avatar
		.resize(thumbnail().width(60).height(60).zoom(0.8).gravity(focusOn(FocusOn.face())))
		.adjust(autoBrightness())
		.delivery(quality(90))
		.roundCorners(byRadius(30, 30, 0))
		.delivery(format('png'));

	const url = avatar.toURL();

	return (
		<table
			id={'table-root'}
			style={{
				wordSpacing: 'normal',
				width: '370px',
				borderSpacing: '6px',
				borderCollapse: 'separate',
			}}
		>
			<tr
				className="mj-column-px-80 mj-outlook-group-fix"
				style={{
					fontSize: 0,
					textAlign: 'left',
					direction: 'ltr',
					verticalAlign: 'middle',
					width: '100%',
				}}
			>
				<td>
					<table>
						<tr>
							<td style={{ paddingRight: '13px' }}>
								<img
									height="34"
									width="152"
									src={WefundLogoGif}
									alt={'wegund logo'}
									style={{
										border: 0,
										display: 'block',
										outline: 'none',
										textDecoration: 'none',
										height: 'auto',
										width: '152px',
										fontSize: '13px',
									}}
								/>
							</td>
						</tr>
					</table>
					<table style={{ width: 'max-content' }}>
						<tr>
							{url && (
								<td
									style={{
										paddingTop: '20px',
										paddingRight: '13px',
										width: '60px',
									}}
								>
									<img
										height="60"
										width="60"
										src={url}
										alt={'Portrait of the user'}
										style={{
											border: 0,
											display: 'block',
											outline: 'none',
											textDecoration: 'none',
											height: 'auto',
											width: '60px',
										}}
									/>
								</td>
							)}
							<td
								className="mj-column-px-520 mj-outlook-group-fix"
								style={{
									fontSize: 0,
									textAlign: 'left',
									verticalAlign: 'middle',
									paddingTop: '15px',
								}}
							>
								<table style={{ borderCollapse: 'collapse' }}>
									<tr
										style={{
											fontFamily: 'Arial',
											fontSize: '16px',
											lineHeight: 'normal',
											textAlign: 'left',
											fontWeight: 700,
										}}
									>
										<td>
											<strong>{name}</strong>
										</td>
									</tr>
									<tr
										style={{
											fontFamily: 'Arial',
											fontSize: '9px',
											letterSpacing: 1.2,
											lineHeight: '8px',
											textAlign: 'left',
											color: '#9180FB',
											textTransform: 'uppercase',
											fontWeight: 600,
										}}
									>
										<td style={{ paddingTop: 4 }}>{occupation.toUpperCase()}</td>
									</tr>
								</table>
							</td>
							<td style={{ width: '10px' }}></td>
							<td>
								<table style={{ marginTop: '20px' }}>
									<tr>
										<td
											style={{
												verticalAlign: 'middle',
												borderRight: '1px solid #9180FB',
												height: '55px',
											}}
										></td>
									</tr>
								</table>
							</td>
							<td style={{ width: '10px' }}></td>
							<td
								style={{
									fontSize: 0,
									textAlign: 'left',
									verticalAlign: 'middle',
								}}
							>
								<table style={{ borderCollapse: 'collapse' }}>
									<tr
										style={{
											fontFamily: 'Arial',
											fontSize: '11px',
											textAlign: 'left',
											color: '#a7a9ab',
										}}
									>
										<td style={{ paddingTop: '20px' }}>
											<a
												href={`tel:${phone.split(' ').join('')}`}
												style={{
													color: '#000',
													textDecoration: 'none',
												}}
											>
												{phone}
											</a>
										</td>
									</tr>
									<tr
										style={{
											fontFamily: 'Arial',
											fontSize: '11px',
											textAlign: 'left',
											color: '#a7a9ab',
										}}
									>
										<td style={{ paddingTop: '0px', color: '#9180FB' }}>
											<a
												href={`mailto:${email}`}
												style={{
													color: '#000',
													textDecoration: 'none',
												}}
											>
												{email.toLowerCase()}
											</a>
										</td>
									</tr>
									<tr
										style={{
											fontFamily: 'Arial',
											color: '#000',
											textDecoration: 'none',
											fontSize: '11px',
										}}
									>
										<td style={{ paddingTop: '2px' }}>DOCK IN FIVE, Praha</td>
									</tr>
									<tr
										style={{
											fontFamily: 'Arial',
											color: '#a7a9ab',
											textDecoration: 'none',
											fontSize: '11px',
										}}
									>
										<td style={{ paddingTop: '0px' }}>
											<a
												href="https://www.wefund.cz"
												style={{ color: '#000', textDecoration: 'none' }}
											>
												www.wefund.cz
											</a>
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
				</td>
			</tr>
			{/*[if mso | IE]></td></tr></table><![endif]*/}
		</table>
	);
};

export default Generated;
