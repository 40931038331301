import '../styles/components/Nav.scss';

const Nav = () => {
	return (
		<nav className="navbar">
			<div className="logo">
				<img src="/logo.png" alt="wefund logo" />
			</div>
		</nav>
	);
};

export default Nav;
