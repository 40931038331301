import { useMemo, useState } from 'react';
import csLang from '../cs.json';
import '../styles/components/Form.scss';

type FormProps = {
	onGenerateClick: (data: FormData) => void;
	onCopyToClipboardClick: (data: FormData) => void;
};

export type FormData = {
	name: string;
	occupation: string;
	phone: string;
	email: string;
	publicId: string;
};

const Form = ({ onGenerateClick, onCopyToClipboardClick }: FormProps) => {
	const [name, setName] = useState('');
	const [occupation, setOccupation] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');

	const [publicId, setPublicId] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const isButtonDisabled = (): boolean => {
		return name === '' || occupation === '' || phone === '' || email === '';
	};
	const buttonDisabled = isButtonDisabled();
	const uploadWidget = useMemo(() => {
		//TODO: types copied from unica
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		return window?.cloudinary?.createUploadWidget(
			{
				cloudName: 'yolkstudio',
				uploadPreset: 'rl9u2v5o',
				language: 'cs',
				text: csLang,
				// cropping: true,
				styles: {
					palette: {
						window: '#FFF',
						windowBorder: '#90A0B3',
						tabIcon: '#61508F',
						menuIcons: '#5A616A',
						textDark: '#000000',
						textLight: '#FFFFFF',
						link: '#61508F',
						action: '#61508F',
						inactiveTabIcon: '#0E2F5A',
						error: '#F44235',
						inProgress: '#907bd7',
						complete: '#20B832',
						sourceBg: '#E4EBF1',
					},
				},
			},
			async (error: any, result: any) => {
				if (!error && result && result.event === 'success') {
					await setPublicId(result.info.public_id);
					await setIsLoading(false);
				} else await setIsLoading(false);
			},
		);
	}, []);

	return (
		<div className="form">
			<label htmlFor="name">Jméno</label>
			<input
				id="name"
				placeholder="Ing. Jan Novák"
				type="text"
				value={name}
				onChange={(e) => setName(e.target.value)}
				required={true}
			/>

			<label htmlFor="occupation">Pozice</label>
			<input
				id="occupation"
				placeholder="Investiční specialista"
				type="text"
				value={occupation}
				onChange={(e) => setOccupation(e.target.value)}
				required={true}
			/>

			<label htmlFor="phone">Telefonní číslo</label>
			<input
				id="phone"
				placeholder="+420 111 222 333"
				type="tel"
				value={phone}
				onChange={(e) => setPhone(e.target.value)}
				required={true}
			/>

			<label htmlFor="email">Email</label>
			<input
				id="email"
				placeholder="novak@wefund.cz"
				type="email"
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				required={true}
			/>

			<div className="buttons">
				<label htmlFor={'upload-button'}>Obrázek</label>

				<button
					id="upload-button"
					className="button"
					onClick={() => {
						setIsLoading(true);
						uploadWidget?.open();
					}}
					disabled={publicId?.length > 0 || isLoading}
				>
					{isLoading ? (
						<img className="button-loader" src={'/img/loader.svg'} alt={'loader'} />
					) : publicId?.length > 0 ? (
						'Obrázek úspěšně nahrán'
					) : (
						'Nahrát obrázek'
					)}
				</button>
			</div>

			<div className="buttons">
				<button
					disabled={buttonDisabled}
					className="button"
					onClick={() => {
						onGenerateClick({
							name,
							occupation,
							phone,
							email,
							publicId,
						});
					}}
				>
					Generovat podpis
				</button>
				<label className="or">nebo</label>
				<button
					disabled={buttonDisabled}
					className="button"
					onClick={() => {
						onCopyToClipboardClick({
							name,
							occupation,
							phone,
							email,
							publicId,
						});
					}}
				>
					Zkopírovat podpis do schránky
				</button>
			</div>
		</div>
	);
};

export default Form;
