import { useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useNavigate } from 'react-router-dom';
import Form, { FormData } from './components/Form';
import Generated from './components/Generated';
import Nav from './components/Nav';
import './styles/App.scss';
import './styles/components/Generated.scss';
import copyFormatted from './utils/copyFormattedHTML';

function App() {
	const navigate = useNavigate();

	const modalRef = useRef(null);

	const onGenerateClick = ({ name, occupation, phone, email, publicId }: FormData) => {
		let query = `?name=${encodeURIComponent(name)}`;
		query += `&occupation=${encodeURIComponent(occupation)}`;
		query += `&phone=${encodeURIComponent(phone)}`;
		query += `&email=${encodeURIComponent(email)}`;
		query += `&public-id=${encodeURIComponent(publicId)}`;

		navigate('/generated' + query);
	};

	const onCopyToClipboardClick = ({ name, occupation, phone, email, publicId }: FormData) => {
		let result = "<meta charset='utf-8'>";
		result += ReactDOMServer.renderToString(
			<Generated name={name} occupation={occupation} phone={phone} email={email} publicId={publicId} />,
		);

		if (modalRef.current) {
			(modalRef.current as HTMLParagraphElement).style.visibility = 'initial';
		}

		copyFormatted(result);

		setTimeout(() => {
			if (modalRef.current) {
				(modalRef.current as HTMLParagraphElement).style.visibility = 'hidden';
			}
		}, 2000);
	};

	return (
		<div className="App">
			<Nav />
			<div className="wrapper">
				<div className="labels">
					<h1 className="title">Generátor podpisů</h1>
					<p className="description">
						Generujte podpisy jednoduše a rychle. <br />
						Stačí vyplnit údaje formuláře a kliknout na tlačítko.
					</p>
					<p className="description">
						Po stisknutí tlačítka <b>Generovat</b> budete přesměrováni na stránku s vaším podpisem.
						Chcete-li jej použít, stačí stisknout <b>Ctrl+A</b> a <b>Ctrl+C</b> (<b>Cmd+A</b> a <b>Cmd+C</b>{' '}
						pro macOS).
						<br /> Pro lepší výsledky doporučujeme používat prohlížeč Google Chrome. <br /> V případě, že se
						vám nenačítá animované logo v podpisu, vypněte si pro tuto stránku adblock.
					</p>
					<p className="description">
						Pokud stisknete <b>Kopírovat do schránky</b>, váš podpis se automaticky zkopíruje. Po tom jej
						jen vložte pomocí <b>Ctrl+V</b> (<b>Cmd+V</b> pro macOS).
					</p>
				</div>

				<Form onCopyToClipboardClick={onCopyToClipboardClick} onGenerateClick={onGenerateClick} />

				<div className="modal-container">
					<p className="copy-success" ref={modalRef}>
						Úspěšně zkopírováno do schránky!
					</p>
				</div>
			</div>
		</div>
	);
}

export default App;
